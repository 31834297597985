import { tns } from 'tiny-slider/dist/tiny-slider';

// Wyszukiwarka - switche

document.addEventListener('DOMContentLoaded', () => {
    // Wybierz elementy formularzy
    const tyresForm = document.getElementById('tyresForm');
    const regForm = document.getElementById('regForm');
    const rimForm = document.getElementById('rimForm');

    // Wybierz elementy nawigacyjne
    const tyresNavItem = document.querySelector('.search__nav-item:nth-child(1)');
    const regNavItem = document.querySelector('.search__nav-item:nth-child(2)');
    const rimNavItem = document.querySelector('.search__nav-item:nth-child(3)');

    // Funkcja do zarządzania formularzami i klasą 'is-active'
    function toggleForms(activeForm, activeNavItem) {
        // Ukryj wszystkie formularze
        tyresForm.classList.add('hidden');
        regForm.classList.add('hidden');
        rimForm.classList.add('hidden');

        // Usuń klasę 'is-active' z wszystkich elementów nawigacji
        tyresNavItem.classList.remove('is-active');
        regNavItem.classList.remove('is-active');
        rimNavItem.classList.remove('is-active');

        // Pokaż aktywny formularz i ustaw aktywną pozycję w nawigacji
        activeForm.classList.remove('hidden');
        activeNavItem.classList.add('is-active');
    }

    // Dodaj event listener do każdego elementu nawigacji
    tyresNavItem.addEventListener('click', () => toggleForms(tyresForm, tyresNavItem));
    regNavItem.addEventListener('click', () => toggleForms(regForm, regNavItem));
    rimNavItem.addEventListener('click', () => toggleForms(rimForm, rimNavItem));
});


// Carousel
const mainCarousel = ('.carousel__loop');
tns({
    container: mainCarousel,
    slideBy: 1,
    autoplay: true,
    autoplayButton: false,
    autoplayButtonOutput: false,
    loop: true,
    nav: true,
    navPosition: 'bottom',
    controls: false,
    controlsPosition: 'bottom',
    items: 1,
    mouseDrag: true,
});

// Aktualizacja inputów sezonowych

document.querySelectorAll('.season-label').forEach(label => {
    label.addEventListener('click', function() {
        document.querySelectorAll('.season-label').forEach(lbl => lbl.classList.remove('is-active'));
        this.classList.add('is-active');
    });
});